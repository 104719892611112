import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ROLES from "utils/roles";
import { TableBody, TableCell, TableRow, TableHead, Table, Typography, Chip, IconButton } from "@material-ui/core";
import { getApolloClient } from "utils/apolloClientFactory";
import { GET_GROUP_SUMMARIES } from "graphql/queries";
import { Edit } from "@material-ui/icons";
import EditGroup from "components/EditGroup/EditGroup";

class GroupsSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      groups: [],
      group_total: 0,
      edit_group: false,
      groupToEdit: {},
    };
  }

  getGroups = () => {
    if (this.state.user) {
      const role = this.state.user.role;

      if (role.includes(ROLES.ADMIN)) {
        getApolloClient().then((client) => {
          client
            .query({
              query: GET_GROUP_SUMMARIES,
            })
            .then((response) => {
              const groups = response.data.getGroupSummaries;

              this.setState({
                groups,
                group_total: groups.length,
              });
            });
        });
      }
    }
  };

  componentDidMount() {
    this.getGroups();
  }

  handleModalEditGroup = (bool, group) => {
    if (bool) {
      this.setState({ groupToEdit: group });
    } else {
      this.setState({ groupToEdit: {} });
      this.getGroups();
    }
    this.setState({ edit_group: bool });
  };

  render() {
    const { t } = this.props;
    const { groups, group_total, groupToEdit, edit_group } = this.state;

    return (
      <Grid container spacing={40}>
        <Grid item>
          <Typography variant="h4">{t("titles.groups_summary")}</Typography>
        </Grid>
        <Grid container item spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item>
                <Chip label={t("group_stats.nb_total") + group_total} color="primary" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("account.nb_total")}</TableCell>
                <TableCell>{t("account.nb_user_accounts")}</TableCell>
                <TableCell>{t("license_stats.nb_total")}</TableCell>
                <TableCell>{t("license_stats.nb_assigned")}</TableCell>
                <TableCell>{t("license_stats.nb_available")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group) => (
                <TableRow key={group._id}>
                  <TableCell component="th" scope="row">
                    {group.name}
                  </TableCell>
                  <TableCell>{group.nbAccounts}</TableCell>
                  <TableCell>{group.nbUserAccounts}</TableCell>
                  <TableCell>{group.nbLicenses}</TableCell>
                  <TableCell>{group.nbAssignedLicenses}</TableCell>
                  <TableCell>{group.nbLicenses - group.nbAssignedLicenses}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => this.handleModalEditGroup(true, group)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {groupToEdit._id && <EditGroup open={edit_group} group={groupToEdit} onClose={() => this.handleModalEditGroup(false)} />}
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(GroupsSummary);
