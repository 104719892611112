import gql from "graphql-tag";

export const GET_USER = gql`
  query User($userId: String!) {
    getUser(id: $userId) {
      _id
      lastName
      firstName
      email
      profession
      rpps
      role
      type
      lastLogin
      phoneNumber
      department
      group {
        _id
        type
        name
        address {
          number
          street
          city
          postCode
        }
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      _id
      lastName
      firstName
      email
      profession
      service
      rpps
      role
      type
      lastLogin
      phoneNumber
      department
      group {
        _id
        type
        name
        address {
          number
          street
          city
          postCode
        }
        createdBy {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_LICENSE = gql`
  query Licence($licenseId: String!) {
    getLicense(id: $licenseId) {
      _id
      limitedTo
      expirationDate
      attributedTo {
        _id
        lastName
        firstName
        email
        role
        lastLogin
      }
    }
  }
`;

export const GET_NB_LICENSES = gql`
  query NumberOfLicenses($groupId: String!) {
    getNumberOfAvailableLicenses(id: $groupId)
  }
`;

export const GET_GROUP = gql`
  query Group($groupID: String!) {
    getGroup(id: $groupID) {
      _id
      type
      name
      address {
        number
        street
        city
        postCode
      }
      users {
        _id
        lastName
        firstName
        email
        role
        type
        lastLogin
      }
      licenses {
        _id
        attributedTo {
          _id
          firstName
          lastName
          email
          role
        }
        limitedTo
        expirationDate
        flags
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query Group {
    getGroups {
      _id
      type
      name
      address {
        number
        street
        city
        postCode
      }
      users {
        _id
        lastName
        firstName
        email
        role
        type
        lastLogin
      }
      licenses {
        _id
        attributedTo {
          _id
          firstName
          lastName
          email
          role
        }
        limitedTo
        expirationDate
      }
    }
  }
`;

export const GET_GROUP_SUMMARIES = gql`
  query Group {
    getGroupSummaries {
      _id
      name
      nbAccounts
      nbUserAccounts
      nbLicenses
      nbAssignedLicenses
    }
  }
`;

export const GET_GROUP_INFOS = gql`
  query Group($groupID: String!) {
    getGroupInfos(id: $groupID) {
      _id
      type
      name
      phoneNumber
      address {
        number
        street
        city
        postCode
      }
      licenses {
        _id
        attributedTo {
          _id
          firstName
          lastName
          email
          role
        }
        limitedTo
        expirationDate
        flags
      }
    }
  }
`;

export const GET_USERS = gql`
  query Users($usersIds: [String]!) {
    getUsers(ids: $usersIds) {
      _id
      lastName
      firstName
      profession
      role
      type
      email
      rpps
      lastLogin
      phoneNumber
      department
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation UpdateGroupMutation($type: String, $name: String, $phoneNumber: String, $address: GroupAddressInput) {
    createGroup(type: $type, name: $name, phoneNumber: $phoneNumber, address: $address) {
      _id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroupMutation($id: String, $type: String, $name: String, $phoneNumber: String, $address: GroupAddressInput) {
    updateGroup(id: $id, type: $type, name: $name, phoneNumber: $phoneNumber, address: $address) {
      _id
    }
  }
`;

export const ADD_LICENSE = gql`
  mutation UpdateLicenseMutation($groupId: String, $licenseId: String) {
    addLicense(groupID: $groupId, licenseID: $licenseId) {
      _id
      type
      name
    }
  }
`;

export const ATTRIBUTE_LICENSE = gql`
  mutation AttributeLicenseMutation($groupId: String!, $userId: String!) {
    attributeLicenseTo(groupID: $groupId, userID: $userId) {
      _id
    }
  }
`;

export const ATTRIBUTE_TARGET_LICENSE = gql`
  mutation AttributeTargetLicenseMutation($licenseId: String!, $userId: String!) {
    attributeTargetLicenseTo(licenseID: $licenseId, userID: $userId) {
      _id
    }
  }
`;

export const REMOVE_LICENSE = gql`
  mutation RemoveLicenseMutation($groupId: String!, $licenseId: String!) {
    removeLicense(groupID: $groupId, licenseID: $licenseId) {
      _id
    }
  }
`;

export const CREATE_LICENSE = gql`
  mutation CreateLicenseMutation($expirationDate: String!, $limitedTo: [String], $flags: [String]) {
    createLicense(expirationDate: $expirationDate, limitedTo: $limitedTo, flags: $flags) {
      _id
    }
  }
`;

export const SET_LICENSE_FLAGS = gql`
  mutation SetLicenseFlagMutation($licenseId: String!, $flags: [String]) {
    setLicenseFlags(licenseID: $licenseId, flags: $flags) {
      _id
    }
  }
`;

export const DELETE_LICENSE = gql`
  mutation DeleteLicenseMutation($licenseId: String!) {
    deleteLicense(id: $licenseId) {
      _id
    }
  }
`;

export const DELETE_LICENSES = gql`
  mutation DeleteLicensesMutation($groupId: String!, $nb: Int!) {
    deleteLicenses(groupID: $groupId, nb: $nb) {
      _id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUserMutation(
    $lastName: String
    $firstName: String
    $email: String
    $phoneNumber: String
    $role: [String]
    $service: String
    $type: String
    $password:String
  ) {
    createUser(
      lastName: $lastName
      firstName: $firstName
      email: $email
      phoneNumber: $phoneNumber
      role: $role
      service: $service
      type: $type
      password:$password
    ) {
      _id
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUserMutation($groupId: String, $userId: String) {
    addUser(groupID: $groupId, userID: $userId) {
      _id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserMutation(
    $userId: String!
    $lastName: String
    $firstName: String
    $type: String
    $email: String
    $profession: String
    $service: String
    $rpps: String
    $phoneNumber: String
  ) {
    updateUser(
      userID: $userId
      lastName: $lastName
      firstName: $firstName
      type: $type
      email: $email
      profession: $profession
      service: $service
      rpps: $rpps
      phoneNumber: $phoneNumber
    ) {
      _id
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserMutation($userId: String!, $role: [String]) {
    updateUserRole(userID: $userId, role: $role) {
      _id
    }
  }
`;

export const UPDATE_USER_LAST_LOGIN = gql`
  mutation UpdateUserMutation($userId: String!, $lastLogin: String) {
    updateUserLastLogin(userID: $userId, lastLogin: $lastLogin) {
      _id
    }
  }
`;

export const ADD_USER_TO_GROUP = gql`
  mutation AddUserToGroupMutation($userId: String, $groupId: String) {
    addUserToGroup(userID: $userId, groupID: $groupId) {
      _id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUserMutation($userId: String) {
    deleteUser(userID: $userId) {
      _id
    }
  }
`;

export const RESEND_MAIL_USER = gql`
  mutation SendActivationMailsAgainMutation($userId: String) {
    sendActivationMailsAgain(userID: $userId) {
      _id
    }
  }
`;
